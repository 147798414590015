@import '../base/_config.scss';
.is-arrow {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #000;
	content: '';
	display: inline-block;
	height: 0;
	width: 0;
	margin-left: 10px;
}

.header {
	$this: &;
	display: flex;
	flex-wrap: wrap;
	height: auto;
	background-color: #fff;

	&-top {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 140px;
		padding: 30px 0 20px 0;
		#{$this}__logo {
			display: inline-block;
			svg {
				width: 350px;
				height: 90px;
			}
		}
		@media screen and (max-width: $mobile-bp) {
			display: none;
		}
	}

	.c-nav {
		position: relative;
		display: flex;
		width: 100%;
		height: 60px;
		border-top: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea;

		&.is-fixed {
			position: fixed;
			background-color: #fff;
			z-index: 7;
			top: 0;
			left: 0;
			width: 100%;
			.scroll-logo {
				svg {
					transform: translateY(0);
				}
			}
		}

		.hamburger {
			display: none;
			position: absolute;
			right: 0;
			top: 0;
			width: 60px;
			height: 100%;
			align-items: center;
			justify-content: center;
			border-left: 1px solid #eaeaea;
			@media screen and (max-width: $mobile-bp) {
				display: flex;
			}
			svg {
				width: 18px;
				height: auto;
			}
		}

		.scroll-logo {
			position: absolute;
			left: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			overflow: hidden;
			svg {
				transform: translateY(200%);
				transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
				width: 118px;
				height: auto;
			}

			@media screen and (max-width: $nav-hidden-bp) {
				position: relative;
			}

			@media screen and (max-width: $mobile-bp) {
				svg {
					transform: translateY(0) !important;
				}
			}
		}

		.nav {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			flex-direction: row;

			@media screen and (max-width: $nav-hidden-bp) {
				justify-content: flex-start;
			}

			.nav__item {
				padding: 0 15px;
				height: 60px;
				display: flex;
				align-items: center;

				@media screen and (max-width: $nav-hidden-bp) {
					display: none;
				}
			}
			.nav__link {
				color: #111;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 12px;
				letter-spacing: 0.025em;
				&:hover {
					color: #c00000;
				}
			}
			.is-submenu {
				position: relative;
				.is-arrow {
					position: relative;
					top: -2px !important;
				}
				.submenu {
					display: none;
					position: absolute;
					z-index: 3;
					left: 0;
					top: calc(100% - 1px);
					width: auto;
					height: auto;
					white-space: nowrap;
					border-bottom: 1px solid #eaeaea;
					border-left: 1px solid #eaeaea;
					border-right: 1px solid #eaeaea;
					background: #fff;
					padding: 10px 15px;
				}
				.submenu__item {
					width: 100%;
				}
				.submenu__link {
					line-height: 2.5em;
					color: #111;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 12px;
					letter-spacing: 0.025em;
				}
				&:hover {
					.submenu {
						display: block;
					}
				}
			}
		}
		.social {
			position: absolute;
			right: 61px;
			height: 60px;
			margin-left: 15px;
			margin-right: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: $mobile-bp) {
				display: none;
			}
			& > div {
				font-family: 'Playfair Display';
				font-size: 14px;
				color: #666;
				font-style: italic;
				.is-arrow {
					position: relative;
					top: -2px;
				}
			}
		}
		.search {
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
			border-left: 1px solid #eaeaea;
			@media screen and (max-width: $mobile-bp) {
				display: none;
			}
			svg {
				width: 18px;
			}
			&:hover {
				svg {
					fill: #c00000;
				}
			}
		}
	}
}
